<template>
  <div class="auth-wrapper">
    <div class="auth-inner">
      <form class="form" @submit.prevent="login">
        <h3 class="title">Tel number</h3>
        <div class="form-group">
          <label>Telefon raqamingiz</label>
          <input
            type="text"
            required
            autofocus
            placeholder="+998 __ ___ __ __"
            class="form-control mask-tel"
            id="phone"
          />
        </div>
        <button class="btn btn-orange btn-block">Yuborish</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "register",
  data: function () {
    return {
      phone: "",
    };
  },
  methods: {
    login: async function () {
      // eslint-disable-next-line no-undef
      this.phone = $("#phone").val();

      if (await this.$store.dispatch("user/recover", this.phone)) {
        await this.$router.push({ name: "confirmation" });
      }
    },
  },
  mounted() {
    // eslint-disable-next-line no-undef
    if ($(".mask-tel").length) {
      // eslint-disable-next-line no-undef
      $(".mask-tel").inputmask({ mask: "+\\9\\9\\8 99 999 99 99" });
    }
  },
};
</script>

<style scoped></style>
